import React from "react";
import { graphql, withPrefix } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import Img from "gatsby-image/withIEPolyfill";
import BackgroundImage from "gatsby-background-image";

import { Link, FormattedMessage } from "gatsby-plugin-intl";
import { Panel } from "rsuite";

const Customer = (props) => {
  const { title, name } = props.data.markdownRemark.frontmatter;
  const img = props.data.markdownRemark.frontmatter.img.childImageSharp.fluid;
  const location = props.pageContext.language;
  var { html } = props.data.markdownRemark;

  const icons = props.data.allIconsJson.edges;
  const services = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass='page-customer' path={props.location.pathname}>
      <SEO title={title} />
      <BackgroundImage fluid={img} className='customer-img w-100'>
        &nbsp;
      </BackgroundImage>
      <div className='strip-white strip-diagonal customer-tart'>
        <div className='container'>
          <div className='row justify-content-start'>
            <div className='col-12'>
              <div className='service service-single'>
                <h1 className='title'>{title}</h1>

                <div
                  className='content'
                  dangerouslySetInnerHTML={{ __html: html }}
                />

                {name == "residental" ? (
                  <Link to='/solarcalculator/' className='button mb-3'>
                    <FormattedMessage id='solar_calc' />
                  </Link>
                ) : null}

                <div className='services'>
                  <h3>
                    <FormattedMessage id='Related_services' />
                  </h3>
                  <div className='grid-layout py-2'>
                    {services.map((edge) =>
                      edge.node.frontmatter.lang == location ? (
                        edge.node.frontmatter.customer.includes(name) ? (
                          <div
                            key={edge.node.frontmatter.path}
                            className='grid-item pb-1'
                          >
                            <Link to={edge.node.frontmatter.path}>
                              <Panel
                                shaded
                                className='service bg-light-green service-teaser'
                              >
                                <div className='card-content'>
                                  <div className='service-icons'>
                                    {edge.node.frontmatter.icons
                                      .split(",")
                                      .map((icon, i) => {
                                        let match_icon = icons.find(
                                          (el) => el.node.title == icon
                                        );
                                        return match_icon ? (
                                          <div
                                            key={i}
                                            className='service-icon center'
                                          >
                                            <Img
                                              style={{
                                                height: "2.8rem",
                                                width: "2.8rem",
                                              }}
                                              className='icon'
                                              title={match_icon.node.desc}
                                              objectFit='contain'
                                              fluid={
                                                match_icon.node.image
                                                  .childImageSharp.fluid
                                              }
                                            />
                                          </div>
                                        ) : null;
                                      })}
                                  </div>
                                  <h4>{edge.node.frontmatter.title}</h4>
                                  <p>{edge.node.frontmatter.desc}</p>
                                  <p>{edge.node.frontmatter.solution}</p>
                                </div>
                              </Panel>
                            </Link>
                          </div>
                        ) : null
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        desc
        path
        name
        img {
          childImageSharp {
            fluid(quality: 90, maxWidth: 640) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/services/" }
        frontmatter: { active: { eq: true } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            desc
            solution
            customer
            lang
            icons
          }
          excerpt(pruneLength: 300)
        }
      }
    }
    allIconsJson {
      edges {
        node {
          id
          title
          image {
            childImageSharp {
              fluid(quality: 80, maxWidth: 40) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default Customer;
